import CrossStorage from "cross-storage";
const host = window.location.host;
const httpData = host.split(":");
const protocol = window.location.protocol;
const portHttp = httpData ? httpData[0] : '';
let portValue;
const oldUrlList = location.host.split(".");
const endUrl = oldUrlList[oldUrlList.length - 2] + "." + oldUrlList[oldUrlList.length - 1]; // 获取当前地址栏后缀主域名地址
if (host.indexOf('192.168') > -1 || host.indexOf('172.18') > -1) {
  portValue = protocol + '//' + host.split(':')[0] + ':6603'
} else if (host.indexOf('testtwo') > -1) { // 开发
  portValue = protocol + '//' + `testtwogroup.${endUrl}`
} else if (host.indexOf('test') > -1) { // 测试
  portValue = protocol + '//' + `testgroup.${endUrl}`
} else { // 正式
  portValue = protocol + '//' + `group.${endUrl}`
}
const storage = new CrossStorage.CrossStorageClient(portValue + '/hub', {
  timeout: 5000
})

export const getStorage = function (name) {
  return new Promise((resolve, reject) => {
    storage.onConnect().then(() => {
      return storage.get(name)
    }).then(res => {
      resolve(res)
    })
  })
}
