<template>
  <div class="app-wrapper">
    <navbar></navbar>
    <div class="app-content-wrapper row no-gutters" :style="{ height }">
      <sidebar v-show="showMenu" class="sidebar-wrapper col-auto" :style="{ height }" />
      <app-main class="app-main-wrapper col" :style="{ height }"></app-main>
    </div>
    <el-dialog-x-dialog title="提示" :visible.sync="editVisible">
      <div>
        <div class="left">
          <i class="el-icon-warning"></i>
        </div>
        <div class="right">
          抱歉，由于您的商城暂未完成资质认证，商城管理功能暂未开启，请联系您的商城超级管理员进行处理！
        </div>
      </div>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, TagsView } from './components';
import ResizeMixin from './mixin/ResizeHandler';
import Resize from './mixin/resize';
//查询资质认证
import Storage from '@/utils/storage';
import { getShopmsg } from "@/api/account";
import { getUserRolesPermissions, getLoginType } from "@/api/login";
export default {
  name: 'layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView
  },
  data () {
    return {
      showMenu: true,//显示菜单
      MixinIsFormEnterprise: false,//是否企业跳站点
      editVisible: false//无资质认证弹窗
    }
  },
  mixins: [ResizeMixin, Resize],
  computed: {
    sidebar () {
      return this.$store.state.app.sidebar;
    },
    device () {
      return this.$store.state.app.device;
    },
    classObj () {
      return {
        hideSidebar: !this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        // mobile: this.device === "mobile"
      };
    },
    height () {
      return (document.body.clientHeight - (80 * this.scale)) + 'px';
    },
  },
  async mounted () {
    const MixinIsFormEnterprise = await getLoginType().then((res) => {
      // 0 单独登录 1 代表企业跳站点
      return res && res.parentLogin === 1
    });
    if (!MixinIsFormEnterprise) { //站点端单独登录 
      this.getRules()
    }
  },
  methods: {
    handleClickOutside () {
      this.$store.dispatch('closeSideBar', { withoutAnimation: false });
    },
    //查询资质认证权限
    async getRules () {
      const res = await getShopmsg();
      // 企业后台开启了对该站点的资质认证开关
      if (res.qualification_certification_required === 1) {
        // 站点未完成认证
        if (res.qualification_certification_status !== 2) {
          if (this.isauth()) {
            // 判断有资质认证的菜单权限
            console.log("有资质认证的菜单权限");
            this.$router.push({ path: "/setting/qualificationCertification" })
            this.showMenu = false;
          } else {
            this.showMenu = false;
            // 没有资质认证的菜单权限
            this.editVisible = true
            // this.$confirm(
            //   "抱歉，由于您的商城暂未完成资质认证，商城管理功能暂未开启，请联系您的商城超级管理员进行处理！",
            //   "提示",
            //   {
            //     type: "warning",
            //   }
            // );
          }
        }
      }

    },
    //查询是否有资质认证菜单权限
    async isauth () {
      let isauth_show;
      const user = Storage.getItem('seller_user') || JSON.parse(sessionStorage.getItem('from_enter_seller_user'));
      if (user.role_id !== 0) {
        isauth_show = await getUserRolesPermissions(user.role_id).then(
          (data) => {
            const qualificationCertification = data.some(
              (items) => items === "qualificationCertification"
            );
            return qualificationCertification;
          }
        );
      }
      return isauth_show;
    }
  }
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  overflow: scroll hidden;
}

.app-wrapper {
  height: 100vh;
  background: #F5F5F5;
  min-width: 1366px;

  .app-content-wrapper,
  .sidebar-wrapper,
  .app-main-wrapper {
    height: calc(100vh - 80px);
  }
}
</style>
