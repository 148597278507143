<template>
  <x-dialog :proxy="goodsSelectorDialog">
    <el-tabs v-model="params.self_goods" v-if="!isJDSupplier" type="card" @tab-click="tabClick">
      <el-tab-pane v-for="item in fdList" :key="item.navigation_id" :label="item.navigation_name" :name="item.self_goods"
        :disabled="loading" />
    </el-tabs>
    <!-- 礼包列表 -->
    <div v-if="isPackList && isSubjectPage" class="packlist">
      <packSelectorTemplate :show="show" :showChooseBtn="showChooseBtn" :default-data="defaultData"
        @close="(e) => $emit('close', e)" @choose="e => $emit('choose', e)" @chooseAll="e => $emit('chooseAll', e)"
        @loading="loading = false">
      </packSelectorTemplate>
    </div>
    <!-- 商品列表 -->
    <en-table-layout v-else ref="tables" :table-data="tableData.data ? tableData.data : []" :row-key="row => row.id"
      :search="true" :redundancy-height="50" @selection-change="selectionChange" @select="handleSelect"
      @select-all="handleSelectAll" @sort-change="handleSortChange">
      <!-- 企业跳站点时 -->
      <template v-if="MixinIsFormEnterprise">
        <template slot="toolbar">
          <el-form-item>
            <x-sortable @update:sort="$event => handleSortChange({ order: $event, prop: 'time' })">上架时间</x-sortable>
          </el-form-item>
          <el-form-item label="商品分类">
            <el-cascader style="width: 240px;" :props="categoryProxy.props" v-model="category"
              :options="categoryProxy.children" placeholder="请选择商品分类" clearable />
          </el-form-item>
          <el-form-item label="关键词" class="col-auto">
            <el-input style="width: 280px" v-model.trim="params.keywords" placeholder="请输入关键词进行搜索" clearable>
              <el-select style="width: 130px;" v-model="search_type" slot="prepend">
                <el-option label="商品名称" value="goods_name"></el-option>
                <el-option label="商品编号" value="goods_sn"></el-option>
                <el-option v-if="params.self_goods === '3' && $store.getters.isSupplierName||params.self_goods === '4'" label="供应商" value="supplier_name"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="handleSearch({ initPageNo: true, saveParams: true })">
              查询
            </el-button>
          </el-form-item>
          <div class="col"></div>
          <el-form-item>
            <el-button v-if="!showChooseBtn" type="primary" style="margin-left: 5px;" size="small" @click="chooseAll">
              批量挑选
            </el-button>
          </el-form-item>
        </template>

        <el-form slot="search" inline style="padding-top: 12px">
          <el-form-item label="毛利率(市价)">
            <el-input-number style="width: 100px;" size="small" v-model="params.profit_min" placeholder="最低毛利率" />
            <span style="margin: 0 5px;">-</span>
            <el-input-number style="width: 100px;" size="small" v-model="params.profit_max" placeholder="最高毛利率" />
          </el-form-item>

          <el-form-item label="利润率(销价)">
            <el-input-number style="width: 100px;" size="small" v-model="params.shop_profit_min" placeholder="最低利润率" />
            <span style="margin: 0 5px;">-</span>
            <el-input-number style="width: 100px;" size="small" v-model="params.shop_profit_max" placeholder="最高利润率" />
          </el-form-item>

          <el-form-item label="会员价" label-width="66px">
            <el-input-number style="width: 100px;" size="medium" v-model="params.low_price" :min="0"
              placeholder="最低会员价" />
            <span style="margin: 0 5px;">-</span>
            <el-input-number style="width: 100px;" size="medium" v-model="params.high_price" placeholder="最高会员价" />
          </el-form-item>

          <el-form-item label="市场价" label-width="66px">
            <el-input-number style="width: 100px;" size="medium" v-model="params.low_mktprice" :min="0"
              placeholder="最低会员价" />
            <span style="margin: 0 5px;">-</span>
            <el-input-number style="width: 100px;" size="medium" v-model="params.high_mktprice" placeholder="最高会员价" />
          </el-form-item>
          <!------------  getArea------------>
          <el-form-item v-if="params.goods_source === 1" label="地区"
            :style="{ width: areaNum * 110 + 60 + 'px', marginLeft: '49px' }">
            <el-select size="small" class="choose-machine center" v-model="areaSelectData.province" placeholder="省/直辖市"
              style="width:105px;margin-right: 5px" clearable>
              <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id" :key="index">
              </el-option>
            </el-select>
            <el-select v-if="areaNum > 1" size="small" class="choose-machine center" v-model="areaSelectData.city"
              placeholder="城市/地区" style="width:105px;margin-right: 5px" clearable>
              <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
              </el-option>
            </el-select>
            <el-select v-if="areaNum > 2" size="small" class="choose-machine center" v-model="areaSelectData.county"
              placeholder="县/区" style="width:105px;margin-right: 5px" clearable>
              <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id" :key="index">
              </el-option>
            </el-select>
            <el-select v-if="areaNum > 3" size="small" class="choose-machine center" v-model="areaSelectData.town"
              placeholder="乡镇/街道" style="width:105px;margin-right: 5px" clearable>
              <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="params.goods_source === 1 && areaSelectData.province !== ''" label="显示类型"
            :style="{ width: MixinIsFormEnterprise ? '200px' : '180px' }">
            <el-select size="small" class="choose-machine center" v-model="areaSelectData.jd_area_state" placeholder="请选择"
              style="width:100px;">
              <el-option v-for="item in typeList" :key="item.id" :label=item.type :value=item.id></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>

      <!-- 单独登录站点时 -->
      <template v-if="!MixinIsFormEnterprise">
        <template slot="toolbar">
          <x-sortable @update:sort="$event => handleSortChange({ order: $event, prop: 'time' })">上架时间</x-sortable>
          <el-form-item label="关键词" class="col-auto">
            <el-input style="width: 280px" v-model="params.keywords" placeholder="请输入关键词" clearable>
              <el-select style="width: 130px;" v-model="search_type" slot="prepend">
                <el-option label="商品名称" value="goods_name"></el-option>
                <el-option label="商品编号" value="goods_sn"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-form-item label="商品分类">
            <el-cascader style="width: 240px;" :props="categoryProxy.props" v-model="category"
              :options="categoryProxy.children" placeholder="请选择商品分类" clearable />
          </el-form-item>

          <el-button type="primary" size="small" @click="handleSearch({ initPageNo: true, saveParams: true })">
            搜索
          </el-button>
          <div class="col"></div>
          <el-button v-if="!showChooseBtn" type="primary" style="margin-left: 5px;" size="small" @click="chooseAll">
            批量挑选
          </el-button>
        </template>

        <el-form slot="search" inline style="padding-top: 12px">
          <el-form-item label="分销价">
            <el-input-number style="width: 100px;" size="medium" :min="0" v-model="params.lowEnterprice"
              placeholder="最低分销价" />
            <span style="margin: 0 5px;">-</span>
            <el-input-number style="width: 100px;" size="medium" v-model="params.highEnterprice" placeholder="最高分销价" />
          </el-form-item>

          <el-form-item label="市场价">
            <el-input-number style="width: 100px;" size="medium" :min="0" v-model="params.low_mktprice"
              placeholder="最低市场价" />
            <span style="margin: 0 5px;">-</span>
            <el-input-number style="width: 100px;" size="medium" v-model="params.high_mktprice" placeholder="最高市场价" />
          </el-form-item>
          <!------------  getArea------------>
          <el-form-item v-if="params.goods_source === 1" label="地区" :style="{ width: areaNum * 110 + 60 + 'px' }">
            <el-select size="small" class="choose-machine center" v-model="areaSelectData.province" placeholder="省/直辖市"
              style="width:105px;margin-right: 5px" clearable>
              <el-option v-for="(item, index) in this.provinceList" :label="item.name" :value="item.id" :key="index">
              </el-option>
            </el-select>
            <el-select v-if="areaNum > 1" size="small" class="choose-machine center" v-model="areaSelectData.city"
              placeholder="城市/地区" style="width:105px;margin-right: 5px" clearable>
              <el-option v-for="(item, index) in this.cityList" :label="item.name" :value="item.id" :key="index">
              </el-option>
            </el-select>
            <el-select v-if="areaNum > 2" size="small" class="choose-machine center" v-model="areaSelectData.county"
              placeholder="县/区" style="width:105px;margin-right: 5px" clearable>
              <el-option v-for="(item, index) in this.countyList" :label="item.name" :value="item.id" :key="index">
              </el-option>
            </el-select>
            <el-select v-if="areaNum > 3" size="small" class="choose-machine center" v-model="areaSelectData.town"
              placeholder="乡镇/街道" style="width:105px;margin-right: 5px" clearable>
              <el-option v-for="(item, index) in this.townList" :label="item.name" :value="item.id" :key="index">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="params.goods_source === 1 && areaSelectData.province !== ''" label="显示类型"
            :style="{ width: MixinIsFormEnterprise ? '200px' : '180px' }">
            <el-select size="small" class="choose-machine center" v-model="areaSelectData.jd_area_state" placeholder="请选择"
              style="width:100px;">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="有货" :value="1"></el-option>
              <el-option label="无货" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>

      <template slot="table-columns">
        <el-table-column v-if="!showChooseBtn" :reserve-selection="true" type="selection" width="40" />

        <el-table-column label="商品图片" prop="thumbnail" width="80" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.thumbnail" alt="" style="width: 300px">
              <img :src="row.thumbnail" class="goods-cover" alt="" slot="reference" />
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" prop="" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.goods_name }}
          </template>
        </el-table-column>
        <el-table-column label="商品编号" width="120"
          :prop="params.self_goods === '2' ? 'ext_goods_id' : 'sn'"></el-table-column>
        <el-table-column label="商品分类" width="120" prop="cat_name" />
        <el-table-column label="所属供应商" width="100" show-overflow-tooltip
          v-show="MixinIsFormEnterprise&&((params.self_goods === '3' && $store.getters.isSupplierName)|| params.self_goods === '4')">
          <template slot-scope="scope">
            {{ scope.row.seller_name }}
          </template>
        </el-table-column>
        <el-table-column label="会员价" width="90" v-if="MixinIsFormEnterprise" sortable="custom" prop="price">
          <template slot-scope="scope">
            {{ scope.row.price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="分销价" width="90" sortable="custom" prop="enterprisePrice" v-if="isSupplement === '0'">
          <template slot-scope="scope">
            {{ scope.row.enterprise_revise_price | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="市场价" width="90" sortable="custom" prop="market">
          <template slot-scope="scope">
            {{ scope.row.mktprice | unitPrice('￥') }}
          </template>
        </el-table-column>

        <el-table-column label="毛利率" width="90" v-if="MixinIsFormEnterprise" sortable="custom" prop="profit">
          <template slot-scope="scope">
            {{ (scope.row.enterprise_profit || 0) | unitCompare('%') }}
          </template>

          <template slot="header" width="90" slot-scope="{row}">
            <el-tooltip effect="dark" content="毛利率 =（市场价 - 会员价）/ 市场价 * 100%" placement="top">
              <label class="cursor-pointer">毛利率</label>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="销售价" width="90" sortable="custom" prop="revisePrice" v-if="isSupplement === '0'">
          <template slot-scope="scope">
            <span v-if="scope.row.goods_type === 'POINT'">
              {{ scope.row.revise_exchange_money | unitPrice('￥') }} +
              {{ scope.row.revise_exchange_point || 0 }}
              积分
            </span>
            <span v-else>
              {{ scope.row.revise_price | unitPrice('￥') }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="利润率" width="90" v-if="MixinIsFormEnterprise" sortable="custom" prop="shopProfit">
          <template slot-scope="scope">
            {{ scope.row.shop_profit || 0 }}%
          </template>

          <template slot="header" slot-scope="{row}">
            <el-tooltip effect="dark" content="利润率 =（销售价 - 会员价）/ 销售价 * 100%" placement="top">
              <label class="cursor-pointer">利润率</label>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column label="可售库存" width="80">
          <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
          <template slot-scope="scope">
            <template v-if="scope.row.goods_source === 1">
              <template v-if="quantityStatus">{{ scope.row.stock_state_desc }}</template>
              <template v-else>
                <span v-if="scope.row.enable_quantity > 0">有货</span>
                <span v-else>无货</span>
              </template>
            </template>
            <template v-else>
              {{ scope.row.enable_quantity }}件
            </template>
          </template>
        </el-table-column>

        <el-table-column label="实际库存" width="80">
          <!-- 商品来源，0普通商品，1京东商品，2苏宁商品 -->
          <template slot-scope="scope">
            <template v-if="scope.row.goods_source === 1">
              <template v-if="quantityStatus">{{ scope.row.stock_state_desc }}</template>
              <template v-else>
                <span v-if="scope.row.enable_quantity > 0">有货</span>
                <span v-else>无货</span>
              </template>
            </template>
            <template v-else>
              {{ scope.row.quantity || 0 }}件
            </template>
          </template>
        </el-table-column>
        <el-table-column label="终身限购数" width="110" prop="restrict_num" show-overflow-tooltip>
          <template slot="header">
            <el-tooltip style="max-width:200px;" effect="dark" placement="top">
              <label class="cursor-pointer">终身限购数<i class="el-icon-warning-outline"></i></label>
              <div slot="content">
                终身限购数为C端用户终身可在商城内购买同一个商品的总数量，超出则<br />不再允许C端用户购买。限购输入框中不填默认为不限。若后台修改已设<br />置好的限购数，则C端用户已购买数量重新开始统计。</div>
            </el-tooltip>
          </template>
          <template slot-scope="{row}">
            <span v-if="`${row.restrict_num}` !== '-1'">{{ row.restrict_num }}</span>
            <span v-else>不限</span>
            <!-- <el-input v-if="`${row.restrict_num}`!=='-1'" v-model="row.restrict_num" size="mini" @input="changNum($event,$index)" @blur="restrictBlur($index)"></el-input>
            <el-input v-else value="不限" size="mini" @focus="restrictChang($index)"></el-input> -->
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="showChooseBtn">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="choose(scope.row)">
              挑选
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-size="tableData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="tableData.data_total" :pager-count="13">
      </el-pagination>
    </en-table-layout>
  </x-dialog>
</template>

<script>
import mixin from './mixin';
import EnTableLayout from '../../TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import XSortable from '@/components/x-sortable/x-sortable';
import * as API_goods from "../../../src/api/goods";
const packSelectorTemplate = () => import('@/pages/floor/renovation/components/pack-selector/components/main.vue')

export default {
  name: 'EnGoodsPickerFx',
  components: {
    XSortable,
    XDialog,
    EnTableLayout,
    packSelectorTemplate
  },
  mixins: [mixin],
  watch: {

  },
  beforeMount () {
    this.isSubjectPage = JSON.parse(sessionStorage.getItem('subjectPage')) && this.$route.fullPath.includes('/floor/subject')
  },
  methods: {
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === 'province') {
        API_goods.getJDProvince().then(res => {
          this.provinceList = this.dataHandle(res.data.result)
        })
      } else if (type === 'city') {
        API_goods.getJDCity(data.id).then(res => {
          this.cityList = this.dataHandle(res.data.result)
        })
      } else if (type === 'county') {
        API_goods.getJDCounty(data.id).then(res => {
          this.countyList = this.dataHandle(res.data.result)
        })
      } else if (type === 'town') {
        API_goods.getJDTown(data.id).then(res => {
          this.townList = this.dataHandle(res.data.result)
        })
      }
    },
    dataHandle (data) {
      let returnData = []
      const keys = Object.keys(data)
      const values = Object.values(data)
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index]
        }
        returnData.push(data)
      })
      return returnData
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-height: 699px) {
  ::v-deep .el-table--border {
    height: 56.5vh !important;
  }

  .goods-selector-dialog {
    .el-tabs__header {
      margin: 0;
    }

    .el-scrollbar__view {
      height: 80vh;
    }
  }

  .packlist {
    ::v-deep .el-table--border {
      height: 48vh !important;
    }
  }
}

@media screen and (min-height: 700px) {
  ::v-deep .el-table--border {
    height: 63vh !important;
  }

  .goods-selector-dialog {
    .el-tabs__header {
      margin: 0;
    }

    .el-scrollbar__view {
      height: 80vh;
    }
  }

  .packlist {
    ::v-deep .el-table--border {
      height: 58vh !important;
    }
  }
}
</style>

