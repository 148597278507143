export default {
  route: {
    development: 'Development',
    toolManage: 'Tool Manage',
    menuManage: 'Menu Manage',

    dashboard: 'Dashboard',

    supplier: 'Supplier',
    qualifications: 'Qualifications',

    markets: 'Markets',
    market: 'Market',
    market1: 'Market',
    marketGoods: 'Market Goods',
    marketGood: 'Market Good',

    goods: 'Goods Manage',
    goodsList: 'Goods List',
    goodsChange: 'Goods Change',
    goodsImportList: "Goods Import List",
    draftList: 'Draft List',
    goodPublish: 'Good Publish',
    goodEdit: 'Good Edit',
    categoryManage: 'Category Manage',
    tagManage: 'Tag Manage',
    tagManages: 'Tag Manage',
    tagAdd: 'Tag Add',
    shopTagAdd: 'Tag Add',
    recycleStation: 'Recycle Station',
    understock: 'Understock',

    shopPayment: 'Shop Payment',
    povertyAlleviation: 'Poverty Alleviation',
    povertyAlleviationWap: 'Poverty Alleviation',

    finance: 'Finance',
    overview: 'Overview',
    summary: 'Summary',
    financeSummary: 'finance Summary',
    detailed: 'Detailed',
    financeDetailed: 'Finance Detailed',
    dealDetailed: 'Deal Detailed',
    siteFinance: 'Site Finance',
    financeAccount: 'Account',
    guaranteeMoney: 'Guarantee Money',
    invoice: 'Invoice',
    enterpriseOverview: 'Enterprise Overview',
    shopOverview: 'Shop Overview',
    account: 'Account',
    accountg: 'Account',
    accountOrder: 'Account Order',
    accountList: 'Account List',
    accountDetail: 'Account Detail',

    order: 'Manage Order',
    orderList: 'Order List',
    enterpriseOrderList: 'Order List',
    shopOrderList: 'Shop Order List',
    comboOrderList: 'Combo Order List',
    shopRefundList: 'Shop Refund List',
    comboRefundList: 'Combo Refund List',
    logList: 'Log List',
    ranking: 'Ranking',
    unionOrderList: 'Union Order List',
    unionRefundList: 'Union Refund List',
    refundList: 'Refund List',
    enterpriseRefundList: 'Refund List',
    refundDetail: 'Refund Detail',
    logisticsManage: 'Logistics Manage',
    commentsManage: 'Comments Manage',
    settlementManage: 'Settlement Manage',
    settlementDetail: 'settlement Detail',
    receiptHistory: 'Receipt History',
    orderDetail: 'Order Detail',
    supplementList: 'Supplement List',

    shop: 'Manage Shop',
    shopThemesPc: 'Shop Themes Pc',
    shopThemesWap: 'Shop Themes Wap',
    shopSlide: 'Shop Slide',
    shopNav: 'Shop Nav',

    member: 'Member Manage',
    unionMember: 'Union Member',
    memberManage: 'Member Manage',
    memberList: 'Member List',
    memberStaffList: 'Member Staff List',
    seeImport: 'see import',
    memberRecycle: 'Member Recycle',
    goodsComment: 'Goods Comment',
    goodsCommentList: 'Goods Comment List',
    goodsAskList: 'Goods Ask List',
    memberNotification: 'Member Notification',
    notificationHistory: 'Site Notification',
    memberEdit: 'editMember',

    programmeList: 'programme List',
    programmeRecord: 'programme Record',
    programmeDetail: "programme Detail",

    promotions: 'Promotions',
    fullCut: 'Full Cut',
    singleCut: 'Single Cut',
    secondHalfPrice: 'Second Half Price',
    discountManager: 'Discount Manager',
    giftManager: 'Gift Manager',
    groupBuyManager: 'Group Buy Manager',
    timeLimit: 'Time Limit',
    addTimeLimit: 'Add Time Limit',
    activityGoodsData: 'Activity Goods Data',
    groupBuyGoods: 'Group Buy Goods',

    statistics: 'Statistics Manage',
    revenue: 'Revenue',
    dataToday: 'Data Today',
    dealRevenue: 'Deal Revenue',
    orderStatistics: 'Order Statistics',
    goodsStatistics: 'goods Statistics',
    generalityOverview: 'Generality Overview',
    goodsAnalysis: 'Goods Analysis',
    goodsDetailsAnalysis: 'Goods Details Analysis',
    goodsPriceSales: 'Goods Price Sales',
    hotSellingGoods: 'Hot Selling Goods',
    operateReport: 'Operate Report',
    buyAnalysis: 'Buy Analysis',
    salesStatistics: 'Sales Statistics',
    regionalAnalysis: 'Regional Analysis',
    trafficStatistics: 'Traffic Statistics',
    collectStatistics: 'Collect Statistics',
    logManage: 'Log Manage',
    shopFlow: 'Flow',
    shopUser: 'User',
    shopActive: 'Active',
    shopDataToday: 'Data Today',
    shopDataDaily: 'Data Daily',

    application: 'application',
    shopManagement: 'Shop Management',
    setMeal: 'Set Meal',
    setMealAdd: 'Set Meal Add',
    grant: 'Grant',
    grantDetails: 'Grant Details',
    myGrant: 'My Grant',
    grantDetail: 'Grant Detail',

    customer: 'Customer Service',
    consultation: 'Consultation',
    message: 'Message',

    shopAuth: 'Shop Auth',
    shopAssistant: 'Shop Assistant',
    roleManage: 'Role Manage',
    rolePermission: 'Role Permission',

    setting: 'Setting Mange',
    shopSettings: 'Shop Settings',
    address: 'Address',
    goodsWarning: 'Goods Warning',
    gradeApplication: 'Grade Application',

    page: 'Page Manage',
    pcDecoration: 'PC Decoration',
    themeManage: 'PC Theme Manage',
    pcThemeManage: 'Theme Manage',
    fpFloorManage: 'Fp Theme Manage',
    fpMobileFloorManage: 'Fp Theme Manage',
    pcFloorManage: 'Floor Manage',
    pcFocusManage: 'Focus Manage',
    mobileDecoration: 'Mobile Decoration',
    mobileThemeManage: 'Theme Manage',
    mobileFloorManage: 'Floor Manage',
    mobileFocusManage: 'Focus Manage',
    pageSetting: 'Page Setting',
    pcSiteMenu: 'PC Site Menu',
    mobileSiteMenu: 'Mobile Site Menu',
    hotKeyword: 'Hot Keyword',
    childMobileFloorManage: 'Child Mobile Floor Manage',
    childFloor: 'Child Floor',
    loginJournal: 'Login Journal',
    operationJournal: 'Operation Journal',
    specialList: 'SpecialList',

    //工具模块
    tools: 'Tools',
    promotionManage: 'Promotion Manage',
    couponList: 'Coupon List',
    storedCardList: 'Stored Card List',
    saleManege: 'Sale Manege',
    salesList: 'Sales List',
    shareVideoList: 'Share Video List',
    saleCardOpenManagement: 'saleCardOpenManagement',

		// 以下为新加路由
		shopMarket: 'shopMarket',
		marketGooda: 'marketGooda',
		marketGoodb: 'marketGoodb',
		goodEdit1: 'goodEdit1',
		requestInvoice: 'requestInvoice',
		administer: 'administer',
		Invoicemangerdetail: 'Invoicemangerdetail',
		Invoicemangerjilu: 'Invoicemangerjilu',
		Invoicemangerjiludetail: 'Invoicemangerjiludetail',
		mallManagementEdit: 'mallManagementEdit',
  },
  login: {
    logIn: 'Log in',
    username: 'Administrator name',
    password: 'Administrator password',
    validcode: 'Image verification code',
    val_username: 'Please enter the administrator name!',
    val_password: 'Please enter the administrator password!',
    val_validcode: 'Please enter the image verification code!',
    val_form: 'Fill in the form is incorrect, please check!'
  },
  tagsView: {
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  }
}
