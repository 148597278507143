import Vue from 'vue';
import axios from 'axios';
import {Loading} from 'element-ui';
import qs from 'qs';
import md5 from 'js-md5';
import checkToken from '@/utils/checkToken';
import store from '@/store/index';
import {api, api_model} from '@/../ui-domain';
import Storage from '@/utils/storage';
import {Foundation} from '@/../ui-utils';

// 创建axios实例
const service = axios.create({
  baseURL: api.seller, // 基础api
  timeout: 18000000, // 请求超时时间
  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
});
let baseService = axios.create({
  baseURL: api.base, // 基础api
  timeout: 18000000, // 请求超时时间
  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
})

// request拦截器
service.interceptors.request.use(config => {
  // Do something before request is sent
  /** 配置全屏加载 */
  if (config.loading !== false) {
    const {loading} = config
    const is_num = typeof loading === 'number';
    if (is_num) config.loading_num = true
    // config.loading = Loading.service({
    // 	lock: true,
    // 	background: `rgba(0, 0, 0, ${is_num ? loading : '0.8'})`,
    // 	spinner: 'el-icon-loading'
    // })
    config.loading = Loading.service({
      lock: true,
      background: `rgba(0, 0, 0, 0)`,
      spinner: 'el-icon-loading',
      text: '加载中',
      customClass: 'custom-loading'
    })
  }
  // uuid
  config.headers['uuid'] = Storage.getItem('seller_uuid')

  if (sessionStorage.getItem('session')) {
    config.headers['session'] = sessionStorage.getItem('session')
  } else {
    config.headers['session'] = null
  }

  // 获取访问Token
  let accessToken = Storage.getItem('seller_access_token')
  if (accessToken) {
    if (api_model === 'pro') {
      const uid = Storage.getItem('seller_uid')
      const nonce = Foundation.randomString(6)
      const timestamp = parseInt(new Date().getTime() / 1000, 10);
      const sign = md5(uid + nonce + timestamp + accessToken)
      const _params = {
        uid,
        nonce,
        timestamp,
        sign
      }
      let params = config.params || {}
      params = {...params, ..._params}
      config.params = params
    } else {
      config.headers['Authorization'] = accessToken
    }
  }
  /** 进行参数序列化 */
  if ((config.method === 'put' || config.method === 'post') && config.headers['Content-Type'] !== 'application/json') {
    config.data = qs.stringify(config.data, {arrayFormat: 'repeat'})
  }
  return config
}, error => {
  return Promise.reject(error);
})

function isListOverflow(data) {
  return (data.page_no * data.page_size - data.data_total >  data.page_size) && data.page_no > 1;
}

// response 拦截器
service.interceptors.response.use(
  response => {
    return new Promise(resolve => {
      closeLoading(response).then(() => {
        const {data, config} = response;
        const isListResp = data && data.data_total;
        const isListRespOverflow = isListResp && isListOverflow(data);
        const isParamsConfig = config.params && !!config.params.page_no;

        if (isListRespOverflow && isParamsConfig) {
          while (isListOverflow(data)) {
            data.page_no--;
          }

          config.params.page_no = data.page_no;

          request(config).then(resolve);
        } else {
          resolve(data);
        }
      });
    });
  },
  async error => {
    await closeLoading(error);
    const error_response = error.response || {};
    const error_data = error_response.data || {};

    if (error_response.status === 403) {
      if (!Storage.getItem('seller_refresh_token')) return;

      const user = Storage.getItem("seller_user");

      const types = {
        2: '',
        3: 'type=enterprise&',
        4: 'type=supplier&',
        5: 'type=combo-card-shop&',
        7: 'type=double-selection&',
        9: 'type=oneshopping&'
      };

      if (user) {
        window.location.replace(`/login?${types[user.member_type] || ''}forward=${location.pathname}`)
        store.dispatch('fedLogoutAction');
      } else {
        window.location.replace(`/login?forward=${location.pathname}`)
      }
      return;
    }

    if (error.config.message !== false) {
      let _message = error.code === 'ECONNABORTED' ? '连接超时，请稍候再试！' : '网络错误，请稍后再试！'
      Vue.prototype.$message.error(error_data.message || _message)
    }
    return Promise.reject(error)
  }
)

baseService.interceptors = service.interceptors

/**
 * 关闭全局加载
 * 延迟200毫秒关闭，以免晃眼睛
 * @param target
 */
const closeLoading = (target) => {
  const {
    loading,
    loading_num
  } = target.config
  if (!loading) return Promise.resolve();
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      target.config.loading.close();
      resolve();
    }, loading_num ? 0 : 200)
  })
}

export default function request (options) {
  
  // 如果是请求【刷新token、登录、退出】不需要检查token，直接请求。
    if (/seller\/check\/token|seller\/login|seller\/members\/logout/.test(options.url)) {
      if (options.switchToBase) return service(options);
      else return service(options)
    }
    return new Promise((resolve, reject) => {
      checkToken(options).then(() => {
        if (options.switchToBase)  baseService(options).then(resolve).catch(reject);
        else service(options).then(resolve).catch(reject)
      })
    })
}
